import React, {useEffect, useState} from 'react'
import moment from "moment";
const OrderTrackingComponent = ({ data, rank}) => {
    const [active, setActive] = useState(false);


    const handleToggle= () => setActive(!active);
    const statusMap = {}; // Use an object for efficient status-based lookup

    if (data.order_tracking) {
        data.order_tracking.forEach((item) => {
            statusMap[item.status.replace(' ','')] = item; // Add item to the map using its status as the key
        });
    }

    // console.log("OrderTrackingComponent", data)
    // console.log("------------------------------")

    return (
        <>
            <div
                className={`p-3 rounded-lg border border-gray-200 flex-col justify-start items-start gap-3 inline-flex ${rank>1? 'w-[50%]':'w-[100%]' }`}>


                <div className="w-[100%] flex-col justify-start items-start gap-2 flex relative">
                    <div className="self-stretch justify-start items-center gap-[220px] inline-flex ">
                        <div className="grow shrink basis-0 justify-start items-center gap-2 flex">
                            <div className="text-black text-base font-medium font-['Inter'] leading-[22px]">
                                Order tracking activity
                            </div>
                                <div
                                    className="px-2 bg-gray-200  rounded justify-center text-black text-xs font-medium font-['Inter'] leading-[18px] ">
                                    Mark
                                    as in progress
                                </div>
                        </div>
                    </div>
                    <div className="text-stone-500 text-sm font-medium font-['Inter']">{data.serviceTitle}</div>
                </div>
                <div className="self-stretch h-[0px] border border-gray-200"></div>
                <div className="w-[100%] relative flex-wrap">
                    <div
                        className="w-[100%] flex-col justify-center items-start pt-0 pb-[14px] inline-flex relative">


                        <div
                            className="w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute bg-emerald-700 rounded-[90px]"></div>
                        <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full align-center text-center">
                            <i className="fa-solid fa-check pt-1.5 text-white"></i></div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Address</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">
                            {data.serviceAddress &&<>
                                <span>{data.serviceAddress.addressLine1}</span>
                                <span>, {data.serviceAddress.addressLine2}</span>
                                <span>, {data.serviceAddress.city}</span>
                                <span>, {data.serviceAddress.state}</span>
                                <span>, {data.serviceAddress.countryIso2Code}</span>
                            </>}
                        </div>
                    </div>
                    <div
                        className="w-[100%] flex-col justify-center items-start py-2 inline-flex relative">

                        <div
                            className="w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute bg-emerald-700 rounded-[90px]"></div>
                        <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full align-center text-center">
                            <i className="fa-solid fa-check pt-1.5 text-white"></i>
                        </div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Service Booked</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">
                            {moment(new Date(data.serviceBooked)).format("DD MMM YYYY")}  {moment(new Date(data.serviceBooked)).format(" h:mm A")}
                        </div>
                    </div>

                    <div
                        className="w-[100%] flex-col justify-center items-start py-2 inline-flex relative">


                        <div
                            className="w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute bg-emerald-700 rounded-[90px]"></div>
                        <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full align-center text-center">
                            <i className="fa-solid fa-check pt-1.5 text-white"></i>
                        </div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Scheduled</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">
                            {moment(new Date(data.scheduledDate)).format("DD MMM YYYY")} <br /> {data.scheduledStartTime} - {data.ScheduleEndtime}

                        </div>
                    </div>
                    <div
                        className="w-[100%] flex-col justify-center items-start py-2 inline-flex relative">



                        <div
                            className={`w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute ${statusMap['Confirmed'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-[90px]`}></div>
                        <div
                            className={`w-6 h-6 left-0 top-0 absolute ${statusMap['Confirmed'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-full align-center text-center`}>
                            {statusMap['Confirmed'] && <i className="fa-solid fa-check pt-1.5 text-white"></i>}</div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Accepted the Service</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">{statusMap['Confirmed']? <>{moment(new Date(statusMap['Confirmed']['created'])).format("DD MMM YYYY")} {moment(new Date(statusMap['Confirmed']['created'])).format(" h:mm A")}</> :'Not Accepted Yet'} </div>
                    </div>


                    <div
                        className="w-[100%] flex-col justify-center items-start gap-2 inline-flex relative">


                        <div
                            className={`w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute ${statusMap['InTransit'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-[90px]`}></div>
                        <div
                            className={`w-6 h-6 left-0 top-0 absolute ${statusMap['InTransit'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-full align-center text-center`}>
                            {statusMap['InTransit'] && <i className="fa-solid fa-check pt-1.5 text-white"></i>}</div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">In Transit</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">
                            {statusMap['InTransit']? <>{moment(new Date(statusMap['InTransit']['created'])).format("DD MMM YYYY")} {moment(new Date(statusMap['InTransit']['created'])).format("h:mm A")}</>:'Not In Transit Yet'}

                        </div>
                    </div>
                    <div
                        className="w-[100%] flex-col justify-center items-start py-2 inline-flex relative">


                        <div
                            className={`w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute ${statusMap['JobStarted'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-[90px]`}></div>
                        <div
                            className={`w-6 h-6 left-0 top-0 absolute ${statusMap['JobStarted'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-full align-center text-center`}>
                            {statusMap['JobStarted'] && <i className="fa-solid fa-check pt-1.5 text-white"></i>}
                        </div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Job Started</div>
                        <div className="ml-8 text-stone-500 text-sm font-medium font-['Inter']">
                            {statusMap['JobStarted'] ? <>{moment(new Date(statusMap['JobStarted']['created'])).format("DD MMM YYYY")}  {moment(new Date(statusMap['JobStarted']['created'])).format(" h:mm A")}</>:'Not Job Started Yet'}
                        </div>
                    </div>
                    <div
                        className="w-[100%] flex-col justify-center items-start  inline-flex relative">


                        <div
                            className={`w-[3.31px] h-[100%] left-[10.34px] top-[12.47px] absolute ${statusMap['Completed'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-[90px]`}></div>
                        <div
                            className={`w-6 h-6 left-0 top-0 absolute ${statusMap['Completed'] ? 'bg-emerald-700' : 'bg-gray-200'} rounded-full align-center text-center`}>
                            {statusMap['Completed'] && <i className="fa-solid fa-check pt-1.5 text-white"></i>}
                        </div>

                        <div className="ml-8 text-black text-base font-semibold font-['Inter']">Job Completed</div>
                        <div className="ml-8 text-stone-500 text-sm font-semibold font-['Inter']">
                            {statusMap['Completed'] ? <>{moment(new Date(statusMap['Completed']['created'])).format("DD MMM YYYY")} {moment(new Date(statusMap['Completed']['created'])).format(" h:mm A")}</>:'Not Yet'}
                        </div>
                    </div>


                    {/*<div className="w-6 h-6 left-0 top-[72px] absolute">*/}
                    {/*    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full"></div>*/}
                    {/*</div>*/}
                    {/*<div className="w-6 h-6 left-0 top-[144px] absolute">*/}
                    {/*    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full"></div>*/}
                    {/*</div>*/}
                    {/*<div className="w-6 h-6 left-0 top-[216px] absolute">*/}
                    {/*    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full"></div>*/}
                    {/*</div>*/}
                    {/*<div className="w-6 h-6 left-0 top-[288px] absolute">*/}
                    {/*    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full"></div>*/}
                    {/*</div>*/}
                    {/*<div className="w-6 h-6 left-0 top-[360.70px] absolute">*/}
                    {/*    <div className="w-6 h-6 left-0 top-0 absolute bg-emerald-700 rounded-full"></div>*/}
                    {/*</div>*/}
                    {/*<div className="w-6 h-6 left-0 top-[432.70px] absolute bg-gray-200 rounded-full"></div>*/}
                </div>
            </div>

        </>

    );
};

export default OrderTrackingComponent;
