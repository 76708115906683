import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import category from "../assets/images/category.png";
import {useDispatch, useSelector} from "react-redux";
import {pageTitle} from "../reducers/LocalDataSlice";
 
import React, {useEffect, useState} from "react";

export default function NotDataFound(data) {
 
    return (
        
       <div className="item-not-found w-[100%] h-[250px] bg-white rounded-xl justify-center items-center inline-flex">
            
            <h3 className="text-black text-lg font-medium font-['Inter']">Data Not Found</h3> 
        </div> 
    )
}