
import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { fetchTeamMembers } from '../../reducers/TeamMemberSlice'
import { Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import { useNavigate } from "react-router-dom"; 
// import DatePicker from "react-datepicker";
import * as moment from 'moment'
// import CustomDatePickerInput from '../../component/CustomDatePickerInput';
import LoadingComponent from "../../modal/LoadingComponent";  
import ProviderStatusCardComponent from "../../component/card/ProviderStatusCardComponent";  
import ExportFile from "../../component/utility-component/ExportFile";
import {filterDataTable3Col} from "../../utility/FilterDataTable";

const TeamMemberList = () => {
    // const [startDate, setStartDate] = useState(new Date());
    const { teamMembers,  loading, cardData } = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    // const [selectedDate, setSelectedDate] = React.useState(new Date());

    const [query, setQuery] = useState('');
    // const [searchQuery, setSearchQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByStatus, setFilterByStatus] = useState('');
    const navigate = useNavigate();
   
 
    const columns = [
        {name: "Provider ", minWidth: '100px',  cell: (row) =>  <div><strong className='name-title'>{row.workerName}</strong> <br/>{row.email} </div>},
        // {name: "Email", minWidth: '220px', sortable: true, cell: (row) => row.email},
        {name: "Phone",   cell: (row) => row.phone},
        {name: "Company Name", minWidth: '150px', cell: (row) => {return  row.companyName?row.companyName:row.employeeType}},
        {name: "Status", minWidth: '200px', cell: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
 
        {name: "Created",   cell: (row) =>  moment(new Date(row.created)).format("DD MMM YYYY,  h:mm A ")  },
        {name: "Action", width: '100px', cell: (row) =>  <span onClick={() => {
                            navigate(`./${row.workerTextId}` , {
                                state: {name: row.workerName}
                            })
                        }} className="btn btn-default"> Details</span>},
        // {name: "Action", minWidth: '100px', cell: (row) => <Link to={`./${row.workerTextId}`} className="btn btn-default"> Details</Link>},
    ]

        // order = order.filter(item=>item.textId.toLowerCase().includes(query.toLowerCase()))
    // let filteredProvider = filteredData.filter(item => item['workerName']?.toLowerCase().includes(filterByProdider.toLowerCase()))
    // tableData = filteredProvider.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))
    // let tableData2 = tableData.filter(item => item['userName']?.toLowerCase().includes(query.toLowerCase()))
    // let tableData3 = tableData.filter(item => item['textId']?.toLowerCase().includes(query.toLowerCase()))
let uniqueData = '';
    
if(teamMembers){
    let tableData1 = teamMembers.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase())) 
    let tableData = tableData1.filter(item => item['employeeType']?.toLowerCase().includes(filterBy.toLowerCase())) 
    let tableData2 = tableData.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = tableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = tableData.filter(item => item['phone']?.toLowerCase().includes(query.toLowerCase())) 
    uniqueData =filterDataTable3Col('email',tableData2, tableData3, tableData4);
}

 

 

//   filterDataTable, filterDataTable4Col

    useEffect(() => {
        let res=dispatch(fetchTeamMembers())
        if(res){
            // dispatch(breadcrumbTree({' Provider List':'provider'}));
            // document.title = 'Provider Listing';
        }
    }, [dispatch])
console.log('****************************', cardData)


  return (
    <Layout page_title={'Provider List'} breadcrumb={{'Provider List':''}} 
     component={
        <>
    {/*{dataList.loading && <div>Loading ...</div>}*/}
            {loading && <LoadingComponent message={''}/>}
            {/*{error && <ErrorComponent close={handleSuccess}/>}*/}
            {cardData && <ProviderStatusCardComponent data={cardData} />}
    {!loading ?
        <div className='data-table-wrapper'>
              
            {/*<h3 className='title'>Pending Service Area Request</h3>*/}
            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Provider Name, Email Address and, phone Number'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                            aria-hidden="true"></i>
                    </div>
                    <div className="export-btn absolute right-0 top">
                        <ExportFile data={uniqueData} fileName={'Provider-data'}/>
                    </div>
                </div>

            </div>
       <CustomDataTable data={uniqueData} columns={columns}  />
        </div>
  : null }
  </>
    }
    top_content={
        <div className="search-header-area">
            {/* <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterByProdider} onChange={(e) => {
                setFilterByProdider(e.target.value);
            }}>
                <option value=''>Filter By Provider</option>
                {Object.values(getUniqueWorkers()).map(key => (<>{key &&
                    <option key={key} value={key}>{key}</option>}</>))}

            </Form.Select> */}
            <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterBy} onChange={(e) => {
                setFilterBy(e.target.value); 
            }}>
                <option value=''>Filter By Company</option>
                <option value='Corporate'>Corporate</option>
                <option value='Individual'>Individual</option>
                <option value='Under Provider'>Under Provider</option>
            </Form.Select>
            <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterByStatus} onChange={(e) => {
                setFilterByStatus(e.target.value); 
            }}>
                <option value=''>Filter By Status</option>
                <option value='Verified'>Verified</option>
                <option value='Processing'>Processing</option>
                <option value='PhotoId Required'>PhotoId Required</option>
                <option value='Mailing Address Required'>Mailing Address Required</option>
                <option value='Service Selection Required'>Service Selection Required</option>
            </Form.Select>
        </div>
    }></Layout>
  )
}

export default TeamMemberList