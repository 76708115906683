import React from 'react';
import {fetchReviewRatingManagementApi, updateReviewRatingManagementApi} from "../../reducers/TeamMemberSlice";

import {useDispatch} from "react-redux";

const ActionModelComponent = ({data, close}) => {
    const dispatch = useDispatch()

    return (
        <div className='absolute w-[200px] left-[-150px] z-50'> 
 
            <div
            className="w-[200px] pb-1 bg-white rounded-lg shadow border border-gray-200 flex-col justify-center items-center inline-flex ">
            <div
                className="self-stretch px-3 py-2 bg-white rounded-tl-lg rounded-tr-lg justify-start items-center gap-4 inline-flex">
                <div className="text-neutral-600 text-base font-normal font-['Inter']">Settings</div>
            </div>
            <div className="w-[100%]   border border-zinc-300"></div>
            <div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex"
                 onClick={() => {
                    let res = dispatch(updateReviewRatingManagementApi({...data,reviewRatingStatus:'Approved'}))
                     res.then((results) => {
                        //  close('')
                        //  dispatch(fetchReviewRatingManagementApi())
                 })
                     res.catch((error) => {
                     console.error('Promise rejected:', error);
                 });
                 }}>
                <i className="fa fa-check" aria-hidden="true"></i>
                <div className="text-black text-base font-normal font-['Inter']">Publish ffff </div>
            </div>
            <div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex"
                 onClick={() => {let res = dispatch(updateReviewRatingManagementApi({...data,reviewRatingStatus:'Pending'}))
                     res.then((results) => {
                        //  close('')
                        //  dispatch(fetchReviewRatingManagementApi())
                     })
                     res.catch((error) => {
                         console.error('Promise rejected:', error);
                     });}}>
                <i className="fa fa-pause" aria-hidden="true"></i>
                <div className="text-black text-base font-normal font-['Inter']">Pause</div>
            </div>
            <div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex"
                 onClick={() => {let res = dispatch(updateReviewRatingManagementApi({...data,reviewRatingStatus:'Rejected'}))
                     res.then((results) => {
                        //  close('')
                        //  dispatch(fetchReviewRatingManagementApi())
                     })
                     res.catch((error) => {
                         console.error('Promise rejected:', error);
                     });}}>
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                <div className="text-black text-base font-normal font-['Inter']">Rejected</div>
            </div>
        </div>
 <div className='fixed bg-transparent w-[100%] h-[100%] top-0 left-0 z-40'
                         onClick={() => close('')}> ffff</div>
        </div>
    );

};

export default ActionModelComponent;