 
 
 

export default function EmptyComponent() {
 
    return (<div className="item-not-found w-[100%] h-[250px] bg-white rounded-xl justify-center items-center inline-flex">
            
            <h3 className="text-black text-lg font-medium font-['Inter']">Data Not Found</h3> 
        </div> 
  
         
    )
}