import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
// import { SideBarData } from "../data/leftSideBar";
const initialState = {
    loading: false,
    navData: [
        {
            name: "Overview",
            link: "dashboard",
            rank: 15,
            icon: "home.svg",
            child: [],
        },
        {
            name: "Service Items",
            link: "service-items",
            icon: "service-items.svg",
            rank: 1,
            child: [
                {
                    name: "Add Service Items",
                    link: "add-item",
                    child: [],
                },
            ]
        },
        {
            name: "Services",
            link: "service",
            rank: 1,
            icon: "service.png",
            // type: 'dropDown',
            child: [],
        },
        {
            name: "Service Area",
            link: "service-area",
            rank: 1,
            icon: "service-area.svg",
            // type: 'dropDown',
            child: [],
        },
        {
            name: "Provider Management",
            link: "provider",
            rank: 9,
            type: 'dropDown',
            icon: "worker.svg",
            child: [
                // {
                //     name: "Approved Member",
                //     link: "approved",
                //     rank: 9,
                //     child: [],
                // },
                //
                // {
                //     name: "Pre Approve Member",
                //     link: "pre-approve",
                //     rank: 7,
                //     child: [],
                // },
                // {
                //     name: "Pending Member",
                //     link: "pending",
                //     rank: 8,
                //     child: [],
                // },
                {
                    name: "Provider List",
                    link: "provider-list",
                    rank: 9,
                    child: [ 
                    ],
                },

                // {
                //     name: "Category Request",
                //     link: "category",
                //     rank: 9,
                //     child: [
  
                //     ],
                // },
                // {
                //     name: "Service Request",
                //     link: "service",
                //     rank: 9,
                //     child: [
 
                //     ],
                // },

                // {
                //     name: "Area Request",
                //     link: "area",
                //     rank: 9,
                //     child: [ 
                //     ],
                // },
                {
                    name: "Price Management",
                    link: "manage-price",
                    rank: 9,
                    child: [
                        // {name: "Approved", link: "approved", rank: 1, child: [] },
                        // {name: "Pending", link: "pending", rank: 2, child: [] },
                        // {name: "Rejected", link: "rejected", rank: 3, child: [] },
                    ],
                },
                // {
                //     name: "Price Request",
                //     link: "price",
                //     rank: 9,
                //     child: [
                //         // {name: "Approved", link: "approved", rank: 1, child: [] },
                //         // {name: "Pending", link: "pending", rank: 2, child: [] },
                //         // {name: "Rejected", link: "rejected", rank: 3, child: [] },
                //     ],
                // },
            ],
        },
        {
            name: "Customer Management",
            link: "EndUser",
            rank: 15,
            icon: "order.svg",
            child: [],
          
        },
        {
            name: "Order Management",
            link: "order",
            rank: 15,
            icon: "order.svg",
            child: [],
        },
        {
            name: "Tips and Rewards",
            link: "tips-rewards",
            rank: 15,
            icon: "tips.svg",
            child: [],
        },
        {
            name: "Ratings and Reviews",
            link: "ratings",
            rank: 15,
            icon: "ratings.svg",
            child: [],
        },
         {
            name: "Notifications",
            link: "notifications",
            rank: 15,
            icon: "settings.svg",
            child: [],
        },
        {
            name: "Settings",
            link: "setting",
            rank: 15,
            icon: "settings.svg",
            type: 'dropDown',
            child: [
                {
                    name: "Notification Management",
                    link: "admin-notice",
                    rank: 1,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Notification Category",
                    link: "category",
                    rank: 2,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Notification Stage",
                    link: "stage",
                    rank: 2,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Item Setting",
                    link: "item-config",
                    rank: 2,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "FAQ Management",
                    link: "faq",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Worker Designation",
                    link: "worker-designation",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
     
                {
                    name: "Worker Role",
                    link: "worker-role",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Worker Module",
                    link: "worker-module",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
                           {
                    name: "Worker Designation Role",
                    link: "worker-designation-role",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Worker Role Permission",
                    link: "worker-role-permission",
                    rank: 15,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Manage Device Categories",
                    link: "identity",
                    rank: 16,
                    icon: "settings.svg",
                    child: [],
                },
                {
                    name: "Meta Setting",
                    link: "meta",
                    rank: 16,
                    icon: "settings.svg",
                    child: [],
                }
            ],
        },
        
       
    ],
}


const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")
// const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
    }
}

export const addPageMeta = createAsyncThunk(
    "settings/addPageMeta", async (formData) => {
        return axios
            .post(`${url}/workerRolePermission/`, formData, config)
            .then((response) => response.data )
    }
)

const navDataSlice = createSlice({
    name: "navData",
    initialState,
    reducers: {
        appendNavBar(state, action) {
            state.navData = action.payload;
        },
        addChildInServiceItem(state, action) {
            // console.log('addChildInServiceItem', action.payload)
            if (action.payload.rank !== 1) {
                state.navData[action.payload.rank].child = [];
            }
            if (action.payload.rank === 1) {
                state.navData[action.payload.rank].child = [
                    {
                        name: "Add Service Items",
                        link: "add-item",
                        child: [],
                    },
                ];

            }
            if(action.payload.nav!=='') {
                action.payload.nav.forEach((item) => {
                    state.navData[action.payload.rank].child.push({
                        name: item.title,
                        link: `${item.textId}#root`,
                        child: [],
                    });
                });
            }

        },
        // addActiveServiceItem(state, action) {
        //   // console.log('addChildInServiceItem',action.payload)
        //   action.payload.nav.forEach((item) => {
        //     state.navData[action.payload.rank].child[item.rank]={
        //       name: item.title,
        //       link: item.textId,
        //       rank: 1,
        //     };
        //   });
        // }
    }
});


export const {appendNavBar, addChildInServiceItem} = navDataSlice.actions;
export default navDataSlice.reducer





