import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import {BASE_URL} from "../../BaseUrl";
import {useDispatch, useSelector} from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {fetchProviderPriceListApi, fetchReviewRatingManagementApi} from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'


import DatePicker from "react-datepicker";

import RatingComponent from "../../component/ratings/RatingComponent";
import StarRatingComponent from "../../component/ratings/StarRatingComponent";

import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
import StarRatingFromUserComponent from "../../component/ratings/StarRatingFromUserComponent";
import {filterDataTable4Col} from "../../utility/FilterDataTable";
import ActionModelComponent from "../../component/ratings/ActionModalComponent";
import ReviewsRatingsPageComponent from "../../component/ratings/ReviewsRatingsPageComponent";
// import Button from 'react-bootstrap/Button';
import NotDataFound from "../../components/NotDataFound";
import LoadingComponent from "../../modal/LoadingComponent";  

const RatingsListPage = () => {
    // const [startDate, setStartDate] = useState(new Date());
    const [activeTab, setActiveTab] = useState('Approved')
    const {error, loading, reviewRatingData} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    // const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [countRating, setCountRating] = useState('');
    // const [uniqueData, setUniqueData] = useState('');


    const handleClick = (e) => setActiveTab(e);
    const handleCountRating= (e) => setCountRating(e);




    useEffect(() => {
        let res = dispatch(fetchReviewRatingManagementApi())
        res.then((results) => {
            // dispatch(breadcrumbTree({'Ratings and Reviews List': ''}));
            document.title = 'Ratings and Reviews Listing';
            // dispatch(breadcrumbTree([{'Service ': 'service-area'},results.payload.pageBreadcrumb]));
            // dispatch(breadcrumbTree({'Service ':'service-area', 'nur':'dddd'}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch])

    console.log(reviewRatingData)


    return (
        <Layout page_title={'Ratings and Reviews'} breadcrumb={{'Ratings and Reviews List':''}} 
        component={
            <>
              {loading ? <LoadingComponent message={''}/>: <>
          
 
                {reviewRatingData ?
                    <>

                        <div className="w-[100%] flex-col justify-start items-start inline-flex">
                            <div className="justify-start items-start gap-7 inline-flex">
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div
                                        className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Approved' && ' active'}`}
                                        onClick={() => handleClick('Approved')}>Published
                                    </div>

                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Approved' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div className="justify-center items-start gap-2 inline-flex">
                                        <div
                                            className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Pending' && ' active'}`}
                                            onClick={() => handleClick('Pending')}>Pending
                                        </div>
                                        <div
                                            className="px-[5px] py-[1.50px] bg-gray-200 rounded justify-start items-center gap-2.5 flex">
                                            {/*<div*/}
                                            {/*    className="text-center text-black text-xs font-normal font-['Inter']">4*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Pending' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div
                                        className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Rejected' && ' active'}`}
                                        onClick={() => handleClick('Rejected')}>Rejected
                                    </div>
                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Rejected' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                            </div>
                            <div className="self-stretch h-[0px] border border-zinc-300"></div>

                        </div>
                        <div className="w-[100%] flex-col justify-start items-start inline-flex bg-white mt-4 mb-3">
                            {/* {reviewRatingData && activeTab === 'Approved' &&
                                <RatingComponent ratings={reviewRatingData.approved_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_approve_rating}
                                                 weeklyTotalReviews={reviewRatingData.rating_calculation_dict.weekly_approved_ratings}
                                                 countRating={countRating}/>}
                            {activeTab === 'Pending' &&
                                <RatingComponent ratings={reviewRatingData.pending_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_pending_rating}
                                                 weeklyTotalReviews={reviewRatingData.rating_calculation_dict.weekly_pending_ratings}
                                                 countRating={countRating}/>}
                            {activeTab === 'Rejected' &&
                                <RatingComponent ratings={reviewRatingData.rejected_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_rejected_rating}
                                                 weeklyTotalReviews={reviewRatingData.rating_calculation_dict.weekly_rejected_ratings}
                                                 countRating={countRating}/>} */}

                        </div>


                        {reviewRatingData && <ReviewsRatingsPageComponent data={reviewRatingData.reviewRatingArray} filterBy={activeTab} getCountRating={handleCountRating}/>}
                    </> 
                    : <NotDataFound /> }
                    </>}
            </>
        } top_content={
            <>

                {/*<DatePicker*/}
                {/*    showIcon*/}
                {/*    selected={selectedDate}*/}
                {/*    onChange={(date) => setSelectedDate(date)}*/}

                {/*/>*/}
                {/*<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />*/}
                {/*<Button variant="default">*/}
                {/*    Primary button*/}
                {/*</Button>{' '}*/}
                {/*<Button variant="default">*/}
                {/*    Button*/}
                {/*</Button>*/}
            </>
        }></Layout>
    )
}

export default RatingsListPage