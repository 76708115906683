import React from 'react';
// import { Card, Button } from 'react-bootstrap';
import {BASE_URL} from "../../BaseUrl";
import {NameIcon} from "../../utility/StringImageIcon";
function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };

    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
}
const HeaderComponent = ({ profile }) => {
    // console.log(profile.employeeType)

    return (
        <>
            <div className="container mb-[90px]">
                <div className="profile-overview position-relative">
                    <div className="cover-ic rounded-12"></div>
                    <div className="provider-profile position-absolute">
                        {profile && profile.workerSelfie?<div className="profile-circle" style={{backgroundImage: `url(${BASE_URL}${profile.workerSelfie})`}}></div>:
                        
                        <div className="profile-icon"> {NameIcon(`${profile.firstName} ${profile.lastName} `)} 
                        </div>}
                        {/* {profile.firstName} {profile.lastName}  ff */}
                        
                            {/* {profile.selfieData ? 
                                <img src={`data:image/jpeg; base64, ${profile.selfieData}`} alt={profile.firstName}/> : 
                                <img src="/svg/account.svg" alt={profile.firstName}/>}

                             <img
                                src={(profile.workerSelfie ? `${BASE_URL}${profile.workerSelfie}` : "/svg/account.svg")}
                                alt={profile.firstName}/> */}

                        
                        <div className="provider-name mt-2">
                            <h3> {profile.firstName} {profile.lastName} <span>
                                {profile.status === 'Verified' && <img src="/svg/worker-approved.svg" alt=""/>}
                                {profile.status === 'TemporaryBlock' && <img src="/svg/Block.svg" alt=""/>}
                                {profile.status === 'Declined' && <img src="/svg/Cancel.svg" alt=""/>}
                                {profile.status === 'Busy' && <img src="/svg/Busy.svg" alt=""/>}
                                </span></h3>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container mb-4 mt-3">
                <div className="provider-basic-info d-flex gap-3 justify-content-center w-100">
                    {profile.employeeType && <div className="b-name item"><img src="/images/logo.png" alt=""/>
                        <span>{profile.employeeType}</span>
                    </div>}
                    {profile.addressJsonWhileVerification &&<div className="location item"><img src="/svg/location.svg" alt=""/>
                        <span>{ profile.addressJsonWhileVerification[0].cityData} </span>
                    </div>}
                    <div className="joined item"><img src="/svg/Calendar.svg" alt=""/>
                        <span>Joined {formatDate(profile.created)}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderComponent;