import React, {useState} from 'react';
import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
import {BASE_URL} from "../../BaseUrl";
import StarRatingFromUserComponent from "./StarRatingFromUserComponent";
import ActionModelComponent from "./ActionModalComponent";
import {useDispatch} from "react-redux";
import moment from "moment";
import {filterDataTable4Col} from "../../utility/FilterDataTable";
import ExportFile from "../utility-component/ExportFile";
import {fetchReviewRatingManagementApi, updateReviewRatingManagementApi} from "../../reducers/TeamMemberSlice";
import {NameIcon} from "../../utility/StringImageIcon";
const ReviewsRatingsPageComponent = ({data, filterBy, getCountRating}) => {
    const [query, setQuery] = useState('');
    const [rowData, setRowData] = useState(false);
    const dispatch = useDispatch()
    console.log('review data: ',data)
    const columns = [
        {
            name: "Services",
            minWidth: '300px', 
            id: 'review-column',
            sortable: true, selector: (row) => <>
                <div className="service-icon">
                    <img src={`${BASE_URL}/${row.serviceImage}`} alt=''/>
                    <div className="content">
                        <strong>{row.serviceTitle}</strong><br/>
                        <span className='type'>{row.orderTextId}</span>
                    </div>
                </div>
            </>
        },

        {
            name: "Reviewer (Customer)",
            sortable: true,
            minWidth: '200px',
            selector: (row) => <>
                <div className="name-icon-area">
                    
                    {/* {row.userImage?<img src={`${BASE_URL}/${row.userImage}`} alt=''/>:
                    <strong className="name-icon"> 
                    {NameIcon(row.userName)} </strong>} */}
                    <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-black text-sm font-medium font-['Inter']">{row.userName}</div>
                        <div
                            className="text-stone-500 text-xs font-normal font-['Inter'] leading-snug">{row.userEmail}</div>
                    </div>
                </div>
            </>
        },
                {
            name: "Provider", 
            minWidth: '200px',
            sortable: true, selector: (row) =>  <div><strong>{row.providerName}</strong> <br/>{row.providerEmail} </div>  
        },
        {
            name: "Review",
            sortable: true,
            minWidth: '300px', 
            selector: (row) => <>
                <div className="multi-line"> 
                    <StarRatingFromUserComponent rating={row.rateForWorkerByEndUser}/>
                    {/*<div className="justify-start items-start gap-2 inline-flex"></div>*/}
                    <div className="self-stretch text-stone-500 text-sm font-medium font-['Inter'] leading-snug">

                        {row.commentForWorkerByEndUser}
                    </div>
                </div>
            </>
        },

        {
            name: "Date", 
            minWidth: '120px',
            sortable: true,
            selector: (row) =>  moment(new Date(row.reviewRatingDate)).format("DD MMM YYYY,  h:mm A")
        },
        {
            name: "Status",
            minWidth: '120px',
            sortable: true, 
            selector: (row) => <span className={`status ${row.reviewRatingStatus}`}> {row.reviewRatingStatus}</span>
        },
        {
            name: "Action", sortable: true, selector: (row) => <>
                <div className="text-center w-[30px] cursor-pointer related" onClick={() => setRowData(row)}>
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                {row.orderTextId === rowData.orderTextId && 
                    // <ActionModelComponent data={row} close={setRowData}/>
                    <div className=''> 
                            {/*{data.isChecked && */}
                            <div className="w-[200px] pb-1 bg-white rounded-lg shadow   border-gray-200 flex-col justify-center items-center inline-flex absolute w-[200px] right-[30px] z-50">
                                <div
                                    className="self-stretch px-3 py-2 bg-white rounded-tl-lg rounded-tr-lg justify-start items-center gap-4 inline-flex">
                                    <div className="text-neutral-600 text-base font-normal font-['Inter']">Status</div>
                                </div>
                                <div className="w-[100%]   border border-zinc-300"></div>
                               {row.reviewRatingStatus!=='Approved' && <div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex cursor-pointer"
                                     onClick={() => { 
                                        let res = dispatch(updateReviewRatingManagementApi({...row,reviewRatingStatus:'Approved'}))
                                         res.then((results) => {
                                             setRowData('')
                                             dispatch(fetchReviewRatingManagementApi())
                                     })
                                         res.catch((error) => {
                                         console.error('Promise rejected:', error);
                                     });
                                     }}>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    <div className="text-black text-base font-normal font-['Inter']">Publish </div>
                                </div>}
                                
                                {row.reviewRatingStatus!=='Pending' &&  <div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex cursor-pointer"
                                     onClick={() => { 
                                        let res = dispatch(updateReviewRatingManagementApi({...row,reviewRatingStatus:'Pending'}))
                                         res.then((results) => {
                                             setRowData('')
                                             dispatch(fetchReviewRatingManagementApi())
                                     })
                                         res.catch((error) => {
                                         console.error('Promise rejected:', error);
                                     });
                                     }}>
                                    <i className="fa fa-pause" aria-hidden="true"></i>
                                    <div className="text-black text-base font-normal font-['Inter']">Pending </div>
                                </div>}
                                 {row.reviewRatingStatus!=='Rejected' &&<div className="self-stretch px-3 py-1 bg-white justify-start items-center gap-3 inline-flex cursor-pointer"
                                     onClick={() => { 
                                        let res = dispatch(updateReviewRatingManagementApi({...row,reviewRatingStatus:'Rejected'}))
                                         res.then((results) => {
                                             setRowData('')
                                             dispatch(fetchReviewRatingManagementApi())
                                     })
                                         res.catch((error) => {
                                         console.error('Promise rejected:', error);
                                     });
                                     }}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                    <div className="text-black text-base font-normal font-['Inter']">Rejected </div>
                                </div>}
                                
                            </div>
                    <div className='fixed bg-transparent w-[100%] h-[100%] top-0 left-0 z-40'
                         onClick={() => setRowData('')}></div>
                            </div>
                    
                }
            </>
        },
    ]

    let viewTableData = data;
    viewTableData = data.filter(item => item['reviewRatingStatus']?.toLowerCase().includes(filterBy.toLowerCase()))
    let tableData1 = viewTableData.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
    let tableData2 = viewTableData.filter(item => item['userName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = viewTableData.filter(item => item['commentForWorkerByEndUser']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = viewTableData.filter(item => item['providerName']?.toLowerCase().includes(query.toLowerCase()))
    let uniqueData = filterDataTable4Col('orderTextId', tableData1, tableData2, tableData3, tableData4);
    getCountRating(uniqueData.length);

    const headers = ['Order Id','User Name','EndUser Id' ]
    const jsonObject = [];
    if (uniqueData) {
        // Select only the desired properties for each order
        data.map((order, index) => 
            jsonObject.push(Object.values({
                orderTextId: order.orderTextId,
                userName: order.userName,
                endUserTextId: order.endUserTextId
            }))
        )
    }

    return (
        <>
            <div className='data-table-wrapper reviewsDataTable'>
                <div className="w-[100%] flex gap-2.5 relative mb-3">
                    {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                    <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                        <input name="search" placeholder={'Search by Provider, customer, Order Id...'}
                               type="text"
                               className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                               value={query ? query : ''}
                               onChange={(e) => {
                                   setQuery(e.target.value)
                               }}/>
                        <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                              aria-hidden="true"></i></div>
                        <div
                            className="export-btn absolute right-0 top">
                            <ExportFile data={jsonObject} fileName={'Rating-filter-data'} headersData={headers}/>
                        </div>
                    </div>

                </div>

                <ReviewsDataTable data={uniqueData ? uniqueData : data} columns={columns}/>
            </div>
        </>
    );
};

export default ReviewsRatingsPageComponent;