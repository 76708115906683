import React, { useState, useEffect} from 'react';
 
import {changeTeamMemberStatus} from "../../reducers/TeamMemberSlice";
import {useDispatch, useSelector} from "react-redux";
 
import ApproveWorkerModal from "../../modal/ApproveWorkerModal";

const WorkerApproveComponent = () => {
    const {category, zoneList, profile} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
 
    const [model, setModel] = useState(false);
 
    const handleClose = () => setModel(false)
    const [status, setStatus] = useState(profile.status);
    const formData = {
        textId: profile.textId,
        status: 'Verified',
        categoryList:category,
        zoneList:zoneList,
        employeeType: profile.employeeType,

    }
    // const formData = {
    //     ...zoneList, // Spread the existing object
    //     textId: profile.textId,
    //     status: 'Verified'
    // };

    const approveWorkerSubmit = ()=>{
        const res = dispatch(changeTeamMemberStatus(formData))
        res.then((results) => {
            if(!results.error){
            setModel(true) 
            setStatus(results.status)
            }
        
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
 
    }

        // useEffect(() => {
            
        // },[dispatch, profile])

    return (
        <>
        
        
            {status === 'Processing' &&
                <div className="container accept-reject mb-5">
                    <button className="btn btn-bg me-4 rounded-8"> Decline </button>
                    {/*<button className="btn btn-success rounded-8" onClick={() => {*/}
                    {/*    const formData = {*/}
                    {/*        textId: profile.textId,*/}
                    {/*        status: 'Verified'*/}
                    {/*    }*/}
                    {/*    const res = dispatch(changeTeamMemberStatus(formData))*/}
                    {/*    if (res) {*/}
                    {/*        alert('Your request has be Successful!!');*/}
                    {/*    }*/}
                    {/*}}>Approve*/}
                    {/*</button>*/}
                    {model && <ApproveWorkerModal close={handleClose} data={formData}/>}
                    <button className="btn btn-success rounded-8" onClick={()=>approveWorkerSubmit()}>Approve</button>
                    {/*<div className=''>*/}
                    {/*    <button className="btn btn-success rounded-8 save-next">Save and Next</button>*/}
                    {/*</div>*/}
                </div>
             }  
        </>
    );

}

export default WorkerApproveComponent;