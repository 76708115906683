// import {Form, Modal, Button, Image} from "react-bootstrap";
// import Layout from '../../components/Layout';
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
// import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useParams} from 'react-router-dom'
// import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
// import {useDispatch, useSelector} from 'react-redux'
import React, { useState} from "react";
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {useFormik} from "formik";
// import {breadcrumbTree} from '../../reducers/LocalDataSlice'
// import {fetchServices, createService} from "../../reducers/ServiceItemSlice";
// import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
// import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
// import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
// import SuccessComponent from "../../modal/SuccessComponent";
import {NameIcon} from "../../utility/StringImageIcon"; 


const ProdiverCardForPriceRequestComponent = ({ data, title}) => {
    const [active, setActive] = useState(true);
    // const dispatch = useDispatch();
    // const [thumbImage, setImage] = useState(false);
    // const {item, service } = useParams();
    // const navigate = useNavigate();
    // const [clickCount, setClickCount] = useState(0);

    const handleToggle= () => setActive(!active);
    const {item} = useParams()

    // const user_data = data.user_data;
    // const payment_summary = data.payment_summary;
    // const paymentArray = data.paymentArray;
    // const address = data.address.replaceAll("'",'"');
    // const addressArr = JSON.parse(address)
 
 console.log(data.services)

    return (
        <>
            <div
                className="prodiver-price-request w-[100%] bg-white rounded-xl flex-col justify-start items-start gap-0 inline-flex">
                <strong>{title}</strong>
                <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                <div className="w-[100%] self-stretch pr-3 justify-start items-start gap-2 flex relative">
                    {/*{worker.selfieData &&*/}
                    {/*    <img className="w-11 h-11 rounded-full" src={`data:image/jpeg; base64, ${worker.selfieData}`} alt={worker.workerName}/> }*/}

                    {data && data.photo ?  <div className="profile-icon w-11 h-11 rounded-full truncate" style={{backgroundImage: `url(${BASE_URL}${data.photo})`}}>
                      
                        </div> :
                        <strong
                            className="name-icon w-11 h-11 rounded-full bg-[green] text-center text-white py-2.5 pt-[12px]">
                            {NameIcon(data.name)} 
                        </strong>} 
                    <div className="flex-col justify-start items-start  inline-flex">
                        <strong>{data && data.name}</strong>
                        <span>{data && data.totalOrder} Orders </span>
                    </div>
                    <i
                        className={`right-[5px] top-[5px] absolute fa cursor-pointer ${ active ? 'fa-chevron-down':'fa-chevron-right'}`}
                        aria-hidden="true"
                        onClick={handleToggle}
                    ></i>
                </div>


                {active && <>
                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <div className="card-section requested-service-container">
                        <div class="header">
                            <h4 className="title"> Most recent requested service</h4>
                            {/* <Link to="#" className="view-all">View All</Link> */}
                        </div>
                          <div className="sub-header">Last 7 Days</div>
                         {/* <ul class="service-list"> */}
                            {data.services.map((each) => ( 
                                <Link className="service-icon" to={`/provider/${item}/${each.serviceTextId}?ca=${each.categoryTextId}`} >
                                <img src={`${BASE_URL}${each.serviceImage}`} alt="Icon"  />
                                {/* <span>{each.serviceTitle}</span> */} 
                                <div className='content'>
                                            <strong>{each.serviceTitle}</strong> <br />
                                            <span className='type'>{each.pricingBy}  </span>
                                        </div>
                                </Link>
                           
                            ))}
                            {/* </ul> */}
                    </div>  
 
    
                    {/* <div className="flex"><i className="fa fa-envelope mt-1" aria-hidden="true"></i>
                        <span className="email ml-3"> {worker && worker.email}</span>
                    </div> */}

                    {/* <div className="flex capitalize"> <i className="fa fa-cogs" aria-hidden="true"></i>
                        <span className="email ml-3">{data && data.serviceTitle}</span></div> */}


                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <strong> Contact info</strong>
                    <div className="flex items-center"><i className="fa fa-envelope mt-1" aria-hidden="true"></i>
                        <span className="email ml-3"> {data && data.email}</span></div>

                    <div className="flex items-center"><i className="fa fa-phone mt-1" aria-hidden="true"></i>
                  
                        <span className="email ml-3">{data && data.phone}</span>
                    </div> 


                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>

                     {/* <div className="text-black text-sm font-medium font-['Inter']">Address</div> */}
                     <strong> Address</strong>
                        <div className="justify-start items-start gap-2.5 inline-flex">
                            {data &&
                            <div className="address-text">
                                    {data.address.addressLine1Data && data.address.addressLine1Data}
                                    {data.address.addressLine2Data && `, ${data.address.addressLine2Data}`} 
                                    {data.address.cityData && `, ${data.address.cityData}`} 
                                    {data.address.stateData && `, ${data.address.stateData}`} 
                                    {data.address.zipData && `, ${data.address.zipData}`} 
                                    {data.address.country && `, ${data.address.country}`} 
                                    
                            </div>}
                            <div className="w-3.5 h-3.5 rounded-[75px]"></div>
                        </div>
                      
                     
                </>}
            </div>
            <div><br/></div>
        </>

    );
};

export default ProdiverCardForPriceRequestComponent;
